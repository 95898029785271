<template>
  <div class="designer-container">
    <div class="left">
      <label>Template Name</label>
      <input type="text" v-model="name" />

      <div class="button-group">
        <button @click="addText">Add Text</button>
        <button @click="setShowImageModal">Add Image</button>
      </div>

      <div class="swatches row">
        <div class="column swatch" @click="updateWood('poplar')">
          <img src="@/assets/poplar-swatch.png" />
          <div>Poplar</div>
        </div>
        <div class="column swatch" @click="updateWood('maple')">
          <img src="@/assets/maple-swatch.png" />
          <div>Maple</div>
        </div>
        <div class="column swatch" @click="updateWood('oak')">
          <img src="@/assets/oak-swatch.png" />
          <div>Oak</div>
        </div>
        <div class="column swatch" @click="updateWood('purpleheart')">
          <img src="@/assets/purpleheart-swatch.png" />
          <div>Purpleheart</div>
        </div>
        <div class="column swatch" @click="updateWood('zebrawood')">
          <img src="@/assets/zebrawood-swatch.png" />
          <div>Zebrawood</div>
        </div>
        <div class="column swatch" @click="updateWood('mahogany')">
          <img src="@/assets/mahogany-swatch.png" />
          <div>Mahogany</div>
        </div>
        <div class="column swatch" @click="updateWood('bubinga')">
          <img src="@/assets/bubinga-swatch.png" />
          <div>Bubinga</div>
        </div>
      </div>

      <div class="button-group">
        <button class="secondary" @click="save(false)">Save</button>
        <button @click="save(true)">Save & go back</button>
      </div>
    </div>
    <div class="right">
      <div class="top-toolbar">
        <div
          class="font-container"
          :class="{ enabled: activeObj && activeObj.type == 'textbox' }"
          @click="toggleFontDropdown"
        >
          {{ activeObj && activeObj.type === 'textbox' ? activeObj.text.slice(0, 7) : '       ' }}
          <img v-if="activeObj && activeObj.type === 'textbox'" src="@/assets/arrow.svg" />

          <div class="font-dropdown" v-if="showFontDropdown">
            <div
              :style="{ fontFamily: font.family, fontWeight: font.weight }"
              @click="fontFamily(font)"
              :key="font.family"
              v-for="font in fonts"
            >
              {{ activeObj && activeObj.type === 'textbox' ? activeObj.text.slice(0, 8) : '' }}
            </div>
          </div>
        </div>

        <div class="button-group">
          <img alt="left align" title="left align" @click="align('left')" src="@/assets/toolbar/left-align.svg" />
          <img
            alt="center align"
            title="center align"
            @click="align('center')"
            src="@/assets/toolbar/center-align.svg"
          />
          <img alt="right align" title="right align" @click="align('right')" src="@/assets/toolbar/right-align.svg" />
        </div>

        <div class="button-group">
          <button title="size down" @click="textSize('down')">-</button>
          <button title="size up" @click="textSize('up')">+</button>
        </div>

        <img title="undo" alt="undo" @click="undo" src="@/assets/toolbar/undo.svg" />
        <img title="remove object" alt="remove object" @click="removeObject" src="@/assets/toolbar/trash.svg" />
      </div>
      <canvas ref="designer" id="designer" width="390" height="665" style="width: auto; height: 100%;"></canvas>
    </div>

    <div v-html="image"></div>
    <IconSearchModal v-if="showImageModal" @add-icon="addIcon" @close="() => (showImageModal = false)" />
  </div>
</template>

<script>
import { fabric } from 'fabric';
import guidelines from '@/lib/guidelines';
import '@/lib/history';
import fonts from '@/designer-fonts.json';
import axios from 'axios';

import IconSearchModal from '@/components/IconSearchModal';

export default {
  name: 'TemplateDesigner',
  components: { IconSearchModal },
  data() {
    return {
      studio: null,
      showFontDropdown: false,
      id: null,
      wood_type: 'maple',
      image: null,
      name: 'Template 1',
      showImageModal: false,
      activeObj: null,
      fonts,
    };
  },
  mounted() {
    let id = this.$route.params.id;

    if (id === 'new') {
      this.init();
    } else {
      let interval = setInterval(() => {
        let template = this.$store.state.admin.templates.find(e => e._id === this.$route.params.id);
        if (template) {
          clearInterval(interval);
          this.init();
          this.$store.dispatch('setFullPageLoader', false);
          return;
        }

        this.$store.dispatch('setFullPageLoader', true);
      });
    }
  },
  methods: {
    init() {
      let canvas = new fabric.Canvas('designer', {
        selection: true,
        selectionBorderColor: 'black',
        backgroundColor: null,
        preserveObjectStacking: true,
      });

      this.studio = canvas;
      window.canvas = canvas;
      guidelines(canvas);
      // history(canvas);
      canvas.historyInit();
      window.jonstudio = canvas;

      this.studio.on('selection:created', o => {
        this.activeObj = o.target;
        this.activeObj.set({ borderColor: 'black', cornerColor: 'black' });
      });

      this.studio.on('selection:updated', o => {
        this.activeObj = o.target;
        this.activeObj.set({ borderColor: 'black', cornerColor: 'black' });
      });

      this.studio.on('selection:cleared', () => {
        this.activeObj = null;
      });

      /** Snap objects to center while dragging */
      this.studio.on('object:moving', o => {
        let studioCenter = this.studio.width / 2;
        let objCenter = o.target.left + o.target.getScaledWidth() / 2;

        let diff = Math.abs(studioCenter - objCenter);

        if (diff < 20) {
          o.target
            .set({
              left: studioCenter - o.target.getScaledWidth() / 2,
            })
            .setCoords();
        }
      });

      // canvas.on('text:changed', function(opt) {
      //   var t1 = opt.target;
      //   if (t1.width > t1.fixedWidth) {
      //     t1.fontSize *= t1.fixedWidth / (t1.width + 1);
      //     t1.width = t1.fixedWidth;
      //   }
      // });

      setInterval(() => {
        this.activeObject = this.studio.getActiveObject();

        if (!this.activeObj || this.activeObj.type !== 'textbox') {
          this.showFontDropdown = false;
        }
      }, 250);

      if (this.$route.params.id === 'new') {
        fabric.Image.fromURL('/img/wifi-icon.png', myImg => {
          this.studio.add(
            myImg.set({
              top: 439,
              left: 124.25,
            }),
          );
          canvas.clearHistory();
        });
      } else {
        let template = this.$store.state.admin.templates.find(e => e._id === this.$route.params.id);

        this.id = template._id;
        this.name = template.name;

        if (template.wood_type) {
          this.wood_type = template.wood_type;
        } else {
          this.wood_type = 'maple';
        }

        canvas.loadFromJSON(template.fabric_data, () => {
          canvas.clearHistory();
        });
      }

      this.updateWood(this.wood_type);
    },
    async save(goBack) {
      this.studio.discardActiveObject().renderAll();

      let data = this.studio.toJSON();
      let savedTemplate = await axios.post('/api/admin/templates', {
        _id: this.id,
        name: this.name,
        wood_type: this.wood_type,
        fabric_data: data,
        preview_image: this.$refs.designer.toDataURL(),
      });

      this.$store.dispatch('admin/updateTemplate', savedTemplate.data);

      // this.data.id = resp.data._id;
      this.$toasted.show('template saved', {
        type: 'success',
        duration: 3000,
        position: 'bottom-left',
      });

      if (goBack) {
        this.$router.go(-1);
      }
    },
    align(direction) {
      this.studio.getActiveObject().set('textAlign', direction);
      this.studio.renderAll();
    },
    fontFamily(font) {
      this.studio.getActiveObject().set('fontFamily', font.family);
      this.studio.getActiveObject().set('fontWeight', font.weight);

      this.studio.renderAll();
    },
    textSize(direction) {
      let size = this.studio.getActiveObject().fontSize;

      if (direction === 'up') {
        size += 4;
      } else {
        size += -4;
      }

      this.studio.getActiveObject().set('fontSize', size);
      this.studio.renderAll();
    },
    removeObject() {
      this.studio.remove(this.studio.getActiveObject());
    },
    updateWood(type) {
      this.wood_type = type;

      let bkg = require(`@/assets/${this.wood_type}-mask.png`);
      this.studio.setBackgroundImage(bkg, this.studio.renderAll.bind(this.studio), {
        backgroundImageStretch: false,
      });
    },
    test() {
      this.image = this.studio.toSVG();
    },
    addText() {
      var text1 = new fabric.Textbox('Your Text', {
        fontFamily: 'Acme',
        color: 'black',
      });
      this.studio.add(text1);
      this.studio.renderAll();
    },
    toggleFontDropdown() {
      if (this.activeObj && this.activeObj.type === 'textbox') {
        this.showFontDropdown = !this.showFontDropdown;
      }
    },
    setShowImageModal() {
      this.showImageModal = !this.showImageModal;
    },
    addIcon(url) {
      this.showImageModal = false;

      fabric.loadSVGFromURL(url, (objects, options) => {
        var obj = fabric.util.groupSVGElements(objects, options);
        this.studio.add(obj).renderAll();
      });
    },
    undo() {
      this.studio.undo();
    },
    redo() {
      this.studio.redo();
    },
  },
};
</script>

<style lang="scss" scoped>
.designer-container {
  display: flex;
  flex-direction: row;

  svg {
    border: 1px solid black;
  }

  .left {
    border-right: 1px solid white;
    width: 500px;

    .swatches {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      row-gap: 10px;
      margin: 30px 0;

      .swatch {
        cursor: pointer;

        img {
          width: 64px;
          height: 62px;
        }
      }
    }

    button {
      height: auto;
      padding: 5px 20px;
      font-size: 18px;
    }

    .button-group {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: center;

      button {
        margin: 0 5px;
      }
    }
  }

  .right {
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .top-toolbar {
      background-color: rgba(255, 255, 255, 0.5);
      width: 400px;
      height: 40px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      position: relative;

      .button-group {
        display: flex;
        height: 30px;
        margin: 0 5px;
      }

      img,
      button {
        max-height: 20px;
        background-color: white;
        padding: 5px;
        border-radius: 4px;
        cursor: pointer;
        margin: 0 2px;
        height: 30px;
        width: 30px;
        min-height: 30px;
        min-width: 30px;
        max-width: 30px;
        max-height: 30px;
        box-sizing: border-box;

        &:active {
          box-shadow: #807d7d 1px 1px 1px 1px;
          filter: opacity(0.75);
        }
      }

      button {
        &.inactive {
          background-color: black;
        }
      }

      .font-container {
        background-color: white;
        white-space: nowrap;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: black;
        padding: 0 5px;
        height: 30px;
        border-radius: 4px;
        cursor: pointer;
        opacity: 0.4;
        width: 100px;

        &.enabled {
          opacity: 1;
        }

        .font-dropdown {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr 1fr;
          background-color: white;
          top: 100%;
          width: 400px;
          z-index: 100;
          left: 0;
          border-radius: 8px;
          font-size: 32px;
          text-align: center;

          div {
            padding: 10px 10px;

            &:hover {
              background-color: lightgray;
            }
          }
        }
      }
    }
  }

  @include responsive(mobile) {
    .left {
      display: none;
    }
  }
}
</style>
